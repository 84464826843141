<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <h2>Interacción General</h2>
        <v-progress-circular
          v-if="showLoading == true" 
          indeterminate color="primary" 
          size="70" width="7">
        </v-progress-circular>
      </v-col>
      <v-col cols="12" md="4">
        <v-select v-model="params.client" :items="user.clients" label="Selecciona Cliente" dense solo required
          class="mt-3" item-text="clientDesc" item-value="clientId">
        </v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="params.urlDesc" label="Descripción de Interacción" required></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-btn color="primary" to="/tags" class="mt-3">Cancelar</v-btn>
        <v-btn color="primary"
          :disabled="!params.client || !params.urlDesc || (!localFile && !params.url)"
          @click="saveGeneral()" class="ml-3 mt-3">
          Guardar
        </v-btn>
      </v-col>
    </v-row>

    <!-- <v-row class="justify-center">
      <v-col cols="12">
        <v-radio-group v-model="radio" row>
          <v-radio label="URL" value="url"></v-radio>
          <v-radio label="Archivo" value="file"></v-radio>
        </v-radio-group>

      </v-col>
    </v-row> -->

    <v-row class="justify-center">
      <v-col cols="8">

        <v-text-field  v-model="params.url" label="URL"></v-text-field>

        
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import router from '../router/index'
import {db} from '../firebase'
import { doc, updateDoc, collection, getDocs, setDoc } from "firebase/firestore";
import {storage} from '../firebase'
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import { mapState } from 'vuex';

export default {
  name: 'OverallPromotion',
  data() {
    return {
      showLoading: false,
      urlFile: '',
      localFile: null,
      radio: null,
      params: {
        client: '',
        urlDesc: '',
        url: ''
      }
    }
  },

  computed: {

    user(){
      return JSON.parse(sessionStorage.getItem('user'))
      
    }
  },

  methods: {
    async saveGeneral(){
      this.showLoading = true
      if(this.radio === 'url'){
        //console.log("Entro a URL")
        //Obtener tags
        const querySnapshot = await getDocs(collection(db, `tags/${this.params.client}/list`))
        querySnapshot.forEach(async doc =>{
          await this.updateDoc(doc.id, this.params.url)
        })
      router.push('/tags')
      this.showLoading = false
      }
      if(this.radio === 'file'){
        //console.log("Entro a image")
        //Guardar en storage
        const refFile = ref(storage, `${this.params.client}/${this.localFile.name}`)
        const resFile = await uploadBytes(refFile, this.localFile)
        const url = await getDownloadURL(ref(storage, `${this.params.client}/${this.localFile.name}`))

        //Obtener tags
        const querySnapshot = await getDocs(collection(db, `tags/${this.params.client}/list`))
        querySnapshot.forEach(async doc =>{
          await this.updateDoc(doc.id, url)
        })
        router.push('/tags')
        this.showLoading = false
      }
      
    },

    async updateDoc(id, url){
      try {
        const tagRef = doc(db, `tags/${this.params.client}/list`, id)
        await updateDoc(tagRef, {
          urlDesc: this.params.urlDesc,
          url: url,
        })
      } catch (error) {
        console.log(error)
      }
      
    },

    selectFile(event){
      this.localFile = null
      this.urlFile=''
      this.localFile = event.target.files[0]
      
      if(this.localFile.type.match(/^.*image/)){
        //console.log("Es imagen")
        const reader = new FileReader()
        reader.readAsDataURL(this.localFile)
        reader.onload = e => {
          //console.log(e.target.result)
          this.urlFile = e.target.result
      }
      }
      else {
        this.urlFile=''
      }
    },

    
  },

}
</script>

<style>

</style>